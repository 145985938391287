import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { URL_API } from './api';

@Injectable({
  providedIn: 'root'
})
export class AnalyticCategoriesService {

  constructor(
    private http: HttpClient
  ) { }

  get(filter: any = null) {
    return this.http.post(`${URL_API}/analyse/categories/filter`, filter)
  }

  getById(id: number) {
    return this.http.get(`${URL_API}/analyse/categories/${id}`)
  }

  getAnalyticCategorieValues(data:any){
    return this.http.post(`http://127.0.0.1:5000/analyze`, data)
  }

  // updateStatus(data: any) {
  //   return this.http.post(`${URL_API}/analyse/categories/status`, data)
  // }

  save(data: FormData) {
    return this.http.post(`${URL_API}/analyse/categories`, data)
  }

  // update(data: FormData, id: number) {
  //   return this.http.post(`${URL_API}/analyse/categories/${id}`, data)
  // }

  // delete(id: number) {
  //   return this.http.delete(`${URL_API}/analyse/categories/${id}`)
  // }

  saveCategoryFile(data: FormData, category_id:number) {
    return this.http.post(`${URL_API}/analyse/categories/${category_id}/values`, data)
  }

  deleteCategoryFile(id: number) {
    return this.http.delete(`${URL_API}/analyse/categories/files/${id}`)
  }

  updateCategoryFile(data:any) {
    return this.http.post(`${URL_API}/analyse/categories/values/${data.id}`, data)
  }
}
