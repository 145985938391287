import { Component, OnInit } from '@angular/core';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { FormArray, FormBuilder, FormControl, Validators } from '@angular/forms';
import { OptionsService } from 'src/app/services/options.service';
import { DemandsService } from 'src/app/services/demands.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Clipboard } from '@angular/cdk/clipboard';
import Swal from 'sweetalert2';
import { startWith, map } from 'rxjs/operators';
import { Observable } from 'rxjs/internal/Observable';
import { NgSelectConfig } from '@ng-select/ng-select';
import { CategoryService } from 'src/app/services/category.service';
import { ClientsService } from 'src/app/services/clients.service';
import OpenAI from 'openai';
import { NgxFileDropEntry, FileSystemFileEntry, FileSystemDirectoryEntry } from 'ngx-file-drop';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { OpenaiService } from 'src/app/services/openai.service';


@Component({
  selector: 'app-solicitation-form',
  templateUrl: './solicitation-form.component.html',
  styleUrls: ['./solicitation-form.component.scss']
})
export class SolicitationFormComponent implements OnInit {

  dropdownSettings: IDropdownSettings = {
    idField: 'id',
    textField: 'name'
  };

  recurrenceIntervaldropdownSettings: IDropdownSettings = {
    idField: 'id',
    textField: 'name'
  };

  public Editor = ClassicEditor;
  public editorConfig = {
    toolbar: []
  };
  

  loading = false
  loadingSolicitation = false
  
  user: any

  //ai
  loadAi:any;
  insightAi:any;
  selectedInsight:any

  // options list
  managersList: any = []
  categories: any;
  
  // selected itens
  selectedCategories: any = []
  
  // insights
  insights:any;

  // demand
  demandForm: any
  demand: any = {}
  demand_id = 0
  recurring:any = '';
  handled:any;
  recurrence_interval_data:any = [
    {
      id: 1,
     name: 'Diariamente'
    },
    {
      id: 2,
     name: 'Semanalmente'
    },
    {
      id: 3,
     name: 'Mensalmente'
    },
  ];

  recurrence_interval:any = '';
  briefing:any = '';
  send = true;
  
  // files
  public files: NgxFileDropEntry[] = [];
  public readonly MAX_FILE_SIZE_MB = 20;
  greaterFiles:any = [];

  skeleton = false
  selectCategories: any

  formData = new FormData();

  constructor(
    private formBuilder: FormBuilder,
    private demandService: DemandsService,
    private route: ActivatedRoute,
    private router: Router,
    private clipboard: Clipboard,
    private categoryService: CategoryService,
    private clientsService: ClientsService, 
    private http: HttpClient,
    private openaiService: OpenaiService
  ) { }

  ngOnInit() {

    this.clientsService.setClientNavigation("2");
    localStorage.setItem('client_id', "2")

    // if(localStorage.getItem('client_id') != '2')
    //   this.router.navigateByUrl('demands');

    this.getById(2);

    this.demandForm = this.formBuilder.group({
      'name':                   ['',  Validators.required],
      'date':                   ['',  Validators.required],
      'purpose':                ['',  Validators.required],
      'end':                    ['',  Validators.required],
      'recurring':              ['',  Validators.required],
      'handled':                ['',  Validators.required],
      'department':             ['',  Validators.required],
      'requester':              ['',  Validators.required],
      'content':                [''],
      'recurrence_interval':    [''],
      'status_id':              [''],
      'start':                  [''],
      'user_id':                [''],
      'ai':                     true,
      'categories':             [''],
      'solicitation':           true
    })

    this.demandForm.controls['date'].setValue(new Date().toISOString().split('T')[0]);
    this.demandForm.controls['status_id'].setValue(1);
    this.demandForm.controls['user_id'].setValue(0);

    this.dropdownSettings = {
      singleSelection: false,
      idField: 'id',
      textField: 'name',
      selectAllText: 'Selecionar todos',
      unSelectAllText: 'Desmarcar todos',
      searchPlaceholderText: 'Buscar',
      itemsShowLimit: 10,
      allowSearchFilter: true
    }

    this.recurrenceIntervaldropdownSettings = {
      singleSelection: true,
      idField: 'id',
      textField: 'name',
      itemsShowLimit: 10,
      enableCheckAll: false,
      allowSearchFilter: false,
      closeDropDownOnSelection: true
    }
  }

  
  public dropped(files: NgxFileDropEntry[]) {
    // this.files = files;
    for (const droppedFile of files) {

      if (droppedFile.fileEntry.isFile) {
        const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
        fileEntry.file((file: File) => {

           let fileSizeMB = file.size / (1024 * 1024);
           if (fileSizeMB > this.MAX_FILE_SIZE_MB) {
             this.greaterFiles.push(`${droppedFile.relativePath} (${fileSizeMB.toFixed(2)} MB)`);
           } else {
            this.greaterFiles = []
            this.files.push(droppedFile);
            // console.log(droppedFile.relativePath);
            this.formData.append('files[]', file, droppedFile.relativePath);
        }
        });
      } else {
        const fileEntry = droppedFile.fileEntry as FileSystemDirectoryEntry;
        // console.log(droppedFile.relativePath, fileEntry);
      }
    }
  }

  public fileOver(event:any){
    // console.log(event);
  }

  public fileLeave(event:any){
    // console.log(event);
  }

  toggleSelectedInsight(selectedIndex:any){
    this.insights.forEach((insight:any, index:any) => {
      insight.selected = index === selectedIndex;
    });
    
    this.selectedInsight = this.insights.filter((i:any) => {
      return i.selected
    })

    console.log(this.selectedInsight[0])
  }

   getOpenAIResponse(prompt: any) {
    this.openaiService.getResponseSolicitation(prompt).subscribe(
      (response: any) => {
        this.loadAi = false
        this.insights = response;
      },
      error => {
        console.error('Erro:', error);
      },
      () => {
        this.insights.map((i:any) => i.insight = i.title + "<br><br>" + i.content + "<br><br>" + i.hashtags);
      }
    );
  }

  getById(id: any) {
    this.skeleton = true
    this.clientsService.getById(id).subscribe({
      next: (result: any) => {
        this.skeleton = false;
        this.getOptions();
      },
      error: (err) => console.log(err)
    });
  }

  getInputsValues() {
    let s: any = []
    this.categories.forEach((c: any) => {
      if (c.selecteds.length > 0) {
        c.selecteds.forEach((item: any) => {
          this.formData.append("categories[]", item.id);
        });
        
      }
    });

    console.log(this.formData.getAll("categories[]"));

    if(this.demandForm.controls['recurrence_interval']?.value.length > 0)
    this.formData.append('recurrence_interval', this.demandForm.controls['recurrence_interval']?.value[0].id)
  }

  getDemandInsight(){
    let insight = this.insights.filter((i:any) => {
      return i.selected
    })[0]
    console.log(insight);
    this.formData.append('content', insight.insight);
  }

  generateInsights(){
    this.loadAi = true;
   this.getOpenAIResponse(this.briefing);
  }

  showThumbnail(e:any) {
    if (e.target.files.length > 0) {
      let file: File = e.target.files[0]
      this.demandForm.patchValue({
        fileSource: file
      });
    }
  }

  save(data: any, sendInsight = false) {
    sendInsight ? this.loading = true : this.loadingSolicitation = true

    Object.keys(this.demandForm.controls).forEach(key => {
      const value = this.demandForm.get(key)?.value;
  
      if (value !== null && value !== undefined) {
          this.formData.append(key, value);
      }
    })

    this.getInputsValues();
    
    if (sendInsight) this.getDemandInsight()

    this.demandService.saveSolicitation(this.formData).subscribe({
      next: (result: any) => {
        console.log(result);
        this.loading = false
        this.loadingSolicitation = false;

        Swal.fire({
          position: 'center',
          title: 'Demanda enviada com sucesso!',
          text: 'Nossa equipe retornará em breve.',
          icon: 'success',
          confirmButtonText: 'OK',
          customClass: {
            title: 'swal-title', 
            confirmButton: 'swal-btn'
          },
          allowOutsideClick: false,
          allowEscapeKey: false,
          showCloseButton: false,
          }).then((result) => {
              if (result.isConfirmed) {
                  location.reload();
              }
        });
      
      },
      error: (err) => {
        console.log(err)
        this.loading = false
        this.loadingSolicitation = false;
      }
    })
  }


  getOptions() {
    this.categoryService.get().subscribe({
      next: (result:any) => {
        this.categories = result.data.filter((c:any) => c.name === 'Área');
        console.log(this.categories);
      },
      error: (err) => console.log(err)
    })
  }

  viewText() {
    this.demandService.getText(this.demand_id).subscribe({
      next: (result: any) => {
        // console.log(result)
        Swal.fire({
          title: 'Versão texto',
          customClass: "text-version",
          width: 800,
          html: result.response,
          showConfirmButton: false,
          showCloseButton: true,
          confirmButtonText: 'ok'
        })
      }
    })
  }

  copyText() {
    this.demandService.getText(this.demand_id).subscribe({
      next: (result: any) => {
        this.clipboard.copy(result.response)
        Swal.fire({
          position: 'top-end',
          icon: 'success',
          text: 'Texto copiado com sucesso',
          showConfirmButton: false,
          timer: 1500
        })
      }
    })
  }
}