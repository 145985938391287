<app-page-default [modal]="modal">
    <app-box-status *ngIf="!modal"></app-box-status>
    <div class="row mt-4">
        <div class="col-lg-12 col-12 mx-auto">
            <div class="card card-body z-index-2 h-100 pb-4">

                <ngx-skeleton-loader 
                    *ngIf="skeleton"
                    count="5" 
                    appearance="line" 
                    [theme]="{height: '40px', marginBottom: '10px', borderRadius: '1rem'}">
                </ngx-skeleton-loader>

                <form [formGroup]="demandForm" *ngIf="!skeleton">

                    <div class="row align-items-center">
                        <div class="col-lg-6">
                            <h6 class="mb-0">
                                {{ !demand || demand?.approved ? 'Demanda' : 'Solicitação' }}
                            </h6>
                            <p class="text-sm mb-0">
                                {{ !demand || demand?.approved ? 'Crie ou edite a demanda' : 'Aprove a solicitação' }}
                            </p>
                        </div>
                        <div class="col-6 d-flex justify-content-end" *ngIf="demand_id">
                            <a (click)="copyText()" class="btn btn-xs d-flex align-items-center btn-primary mb-0 mx-2">
                                <i class="far fa-copy pe-2"></i> Copiar texto
                            </a>
                            <a (click)="viewText()" class="btn btn-xs d-flex align-items-center btn-primary mb-0">
                              <i class="ni ni-align-left-2 pe-2" aria-hidden="true"></i> Versão texto
                            </a>
                        </div>
                    </div>
                   
                    <hr class="horizontal dark my-3">

                    <div class="row align-items-end">
                        <div class="col-lg-12 mb-4" *ngIf="demandForm.controls['requester'].value">
                            <label class="form-label">Solicitante</label>
                            <input type="text" class="form-control" formControlName="requester" readonly>
                        </div>
                        <div class="col-lg-2">
                            <label class="form-label">Data</label>
                            <input type="date" class="form-control" formControlName="date">
                        </div>
                        <div class="col-lg-2">
                            <label class="form-label">Data final</label>
                            <input type="date" class="form-control" [min]="demandForm.controls['date'].value" formControlName="end">
                        </div>
                        <div class="col-lg-4">
                            <label class="form-label">Responsável</label>
                            <select class="form-control" formControlName="user_id">
                                <option *ngFor="let u of users" [value]="u.id">{{ u.name }}</option>
                            </select>
                        </div>
                        <div class="col-lg-2">
                            <label class="form-label">Número do job</label>
                            <input type="text" class="form-control" formControlName="job_code">
                        </div>
                        <div class="col-lg-2">
                            <div class="form-check mb-3 form-switch">
                                <input class="form-check-input" type="checkbox" formControlName="priority" id="priority">
                                <label class="form-check-label" for="priority">Prioridade</label>
                            </div>
                        </div>
                    </div>

                    <label class="form-label mt-4">Título da demanda</label>
                    <input type="text" class="form-control" formControlName="name">
                    
                    <div class="row">
                        <ng-container formArrayName="categories">
                            <div class="col-lg-6" *ngFor="let c of categories; let i = index">
                                <label class="form-label mt-4">{{c.name}}</label>
                                <ng-multiselect-dropdown
                                    [placeholder]="'Selecione os itens'"
                                    [settings]="dropdownSettings"
                                    [data]="c.values"
                                    [ngModelOptions]="{standalone: true}"
                                    [(ngModel)]="c.selecteds">
                                </ng-multiselect-dropdown>
                            </div>
                        </ng-container>
                        <div class="col-lg-6" *ngIf="demandForm.controls['department'].value">
                            <label class="form-label mt-4">Sub área / Departamento</label>
                            <input type="text" class="form-control" formControlName="department">
                        </div>
                    </div>

                    <div class="row mt-4">
                        <div class="col-lg-6">
                            <label class="form-label">Gestor(es) Álamo</label>
                            <ng-multiselect-dropdown
                            [placeholder]="'Selecione os gestores'"
                            [settings]="dropdownSettings"
                            [data]="managersList"
                            [ngModelOptions]="{standalone: true}"
                            [(ngModel)]="selectedManagers"
                            >
                        </ng-multiselect-dropdown>
                        </div>
                        <div class="col-lg-6">
                            <label class="form-label">Status</label>
                            <select class="form-control" formControlName="status_id">
                                <option [value]="s.id" *ngFor="let s of status">{{ s.name }}</option>
                            </select>
                        </div>
                    </div>
                    <div class="row mt-4" *ngIf="demandForm.controls['handled'].value">
                        <div class="col-12">
                            <label class="form-label" for="">Essa demanda já está sendo tratada pela área de Comunicação?</label>
                                <div class="form-group d-flex">
                                    <div class="form-check me-7">
                                        <input class="form-check-input" type="radio" formControlName="handled" name="handled" id="handled_true" [value]="1">
                                        <label class="form-check-label" for="handled_true">
                                        Sim
                                        </label>
                                    </div>
                                    <div class="form-check">
                                        <input class="form-check-input" type="radio" formControlName="handled" name="handled" id="handled_false" [value]="0">
                                        <label class="form-check-label" for="handled_false">
                                        Não
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row mt-4" *ngIf="demandForm.controls['recurring'].value === 0 || demandForm.controls['recurring'].value === 1">
                            <div class="col-12">
                                <div class="col-12">
                                    <label class="form-label">Esse conteúdo é recorrente?</label>
                                    <div class="form-group d-flex align-items-center">
                                        <div class="form-check mb-0 me-7">
                                            <input class="form-check-input" type="radio" name="recurring"
                                            formControlName="recurring" id="recurring_yes"  formControlName="recurring" [value]="1">
                                            <label class="form-check-label m-0" for="recurring_yes">
                                            Sim
                                            </label>
                                        </div>
                                        <div class="form-group d-flex align-items-center m-0">
                                            <label for="" class="text-secondary text-nowrap m-0 me-3">Por quanto tempo?</label>
                                            <ng-multiselect-dropdown
                                                [placeholder]="'Selecione a recorrência'"
                                                [settings]="recurrenceIntervaldropdownSettings"
                                                [data]="recurrence_interval_data"
                                                [disabled]="!demandForm.controls['recurring'].value"
                                                [(ngModel)]="recurrence_interval"
                                                formControlName="recurrence_interval"
                                                style="width: 288px">
                                            </ng-multiselect-dropdown>
                                        </div>
                                        <div class="form-check mb-0 ms-3">
                                            <input class="form-check-input" type="radio" name="recurring"
                                            formControlName="recurring" id="recurring_no" formControlName="recurring" [value]="0" (click)="recurrence_interval = ''">
                                            <label class="form-check-label" for="recurring_no">
                                            Não
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row mt-4">
                            <div class="col-lg-12">
                                <label class="form-label mb-3">Anexo arquivos de apoio (Documentos, referências, logotipo e etc.)</label>
                                <div class="center">
                                    <ngx-file-drop dropZoneLabel="Drop files here" (onFileDrop)="dropped($event)" 
                                    (onFileOver)="fileOver($event)" (onFileLeave)="fileLeave($event)" [multiple]="true">
                                        <ng-template ngx-file-drop-content-tmp let-openFileSelector="openFileSelector">
                                            <button type="button" (click)="openFileSelector()" class="d-block bg-white border-0">
                                                <img src="assets/solicitations/upload-icon.png" alt="">
                                            </button>
                                                <p class="text-center mb-0">Arraste aqui o arquivo ou procure em seu computador<br><small>Máximo de 20MB</small></p>
                                        </ng-template>
                                    </ngx-file-drop>
                                    <div class="upload-table" *ngIf="files.length > 0">
                                        <table class="table">
                                            <tbody class="upload-name-style">
                                                <tr *ngFor="let item of files; let i=index">
                                                    <td><p class="mb-0">{{ item.relativePath }}</p></td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row" *ngIf="greaterFiles?.length > 0">
                            <div class="col-12">
                                <p class="text-sm text-danger fw-bold mb-0 mt-2">Os arquivos abaixo não foram carregados pois ultrapassam o limite máximo de {{MAX_FILE_SIZE_MB}} MB</p>
                                <p><small class="text-danger" *ngFor="let f of greaterFiles">{{f}}</small></p>
                            </div>
                        </div>
                        <div class="row mt-4" *ngIf="demand_files?.length > 0">
                            <div class="col-12">
                                <label class="form-label">Arquivos já publicados:</label>
                                <a class="d-block" [href]="f.src" *ngFor="let f of demand_files" download>{{ f.file_name }}</a>
                            </div>
                        </div>
                    <label class="form-label mt-4">{{demandForm.controls['ai'].value ? 'Texto IA' : 'Descrição'}}</label>
                    <ckeditor [editor]="Editor" formControlName="content"></ckeditor>

                    <label class="form-label mt-4">{{demandForm.controls['ai'].value ? 'Briefing' : 'Objetivo'}}</label>
                    <ckeditor [editor]="Editor" formControlName="purpose"></ckeditor>
                </form>

            </div>
        </div>
    </div>
    <div class="row mt-4" *ngIf="!skeleton">
        <div class="col-lg-12 col-12 mx-auto">
            <div class="card card-body z-index-2 h-100 pb-4">
    
                <h6 class="mb-0">Peças</h6>
                <p class="text-sm mb-0">Gerencie as peças aqui</p>
                <hr class="horizontal dark my-3">
    
                <div class="table-responsive p-0">
                    <table class="table align-items-center mb-0" *ngIf="parts.length > 0">
                        <thead>
                            <tr>
                                <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Peça / Formato</th>
                                <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7" width="5">Quantidade</th>
                                <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7" width="5">Data</th>
                                <th style="width: 20%"></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let p of parts">
                                <td><h6 class="mb-0 text-sm">{{ p.format }}</h6></td>
                                <td><p class="text-sm text-center font-weight-bold mb-0">{{ p.qtd }}</p></td>
                                <td><p class="text-sm text-center font-weight-bold mb-0">{{ p.month | date: "dd/MM/yyyy" }}</p></td>
                                <td class="d-flex justify-content-end">
                                    <button class="btn btn-danger btn-sm m-0 me-1" (click)="deletePart(p)">Remover</button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <small class="text-center d-block" *ngIf="parts.length == 0">
                        Nenhuma peça cadastrada até o momento
                    </small>
                </div>
    
                <hr class="horizontal dark my-3">

                <form [formGroup]="pecaForm">
                    <div class="row align-items-end">
                        <div class="col-lg-6">
                            <label class="form-label mt-4">Peça</label>
                            <!--Using items input-->
                            <ng-select [items]="types" 
                                        bindLabel="name" 
                                        bindValue="name" 
                                        [addTag]="true"
                                        addTagText="Adicionar"
                                        formControlName="format">
                            </ng-select>
                        </div>
                        <div class="col-lg-2">
                            <label class="form-label mt-4">Qtd</label>
                            <input type="number" min="1" class="form-control" formControlName="qtd">
                        </div>
                        <div class="col-lg-2">
                            <label class="form-label mt-4">Data</label>
                            <input type="date" class="form-control" formControlName="month">
                        </div>
                        <div class="col-lg-2">
                            <button type="submit" [disabled]="!pecaForm.valid" (click)="addPeca(pecaForm)"
                                class="btn w-100 btn-success m-0">
                                Adicionar
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>

    <div class="row mt-5">
        <div class="col-lg-6">
            <button type="button" 
                    name="button" 
                    *ngIf="!modal"
                    [disabled]="loading" 
                    routerLink="/demands" 
                    class="btn btn-light m-0">
                        Voltar
            </button>
            <!-- <button type="button" 
                    name="button" 
                    [disabled]="loading" 
                    routerLink="/demands" 
                    class="btn btn-danger ms-2 m-0">
                        Arquivar
            </button> -->
        </div>
        <div class="col-lg-6 col-12 mx-auto">

            <div class="d-flex justify-content-end">
                <button type="button" 
                        name="button" 
                        [disabled]="loading" 
                        (click)="cancel()"
                        class="btn btn-light m-0">
                            {{ modal ? 'Fechar' : 'Cancelar' }}
                </button>
                <button type="button" 
                        name="button" 
                        *ngIf="!demand || demand?.approved"
                        [disabled]="loading || !demandForm.valid"
                        (click)="save(demandForm)"
                        class="btn bg-gradient-primary m-0 ms-2">
                            <span class="spinner-grow spinner-grow-sm" 
                                *ngIf="loading" role="status" 
                                aria-hidden="true">
                            </span>
                            {{ loading ? 'Salvando' : 'Salvar demanda' }}
                </button>
                <button type="button" 
                        name="button" 
                        *ngIf="demand && !demand?.approved"
                        [disabled]="loading || !demandForm.valid"
                        (click)="save(demandForm)"
                        class="btn bg-gradient-primary m-0 ms-2">
                            <span class="spinner-grow spinner-grow-sm" 
                                *ngIf="loading" role="status" 
                                aria-hidden="true">
                            </span>
                            {{ loading ? 'Aprovando' : 'Aprovar demanda' }}
                </button>
            </div>
        </div>
    </div>
</app-page-default>