import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { URL_API } from './api';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class OpenaiService {
  private apiUrl: string = `${URL_API}/openai/generate/text`;

  constructor(
    private http: HttpClient
  ) { }

  getResponseStream(prompt: string): Observable<string> {
    const responseSubject = new Subject<string>();

    const body = {
      // model: 'gpt-4', // Modelo GPT-4
      historic: [
        {
          role: 'system', content: 'Você é um assistente muito prestativo da plataforma Smarthub, sua função é ajudar na análise de dados. Organize os insights de forma clara e estruturada, fornecendo recomendações práticas e acionáveis para melhorar o desempenho e a eficiência dos call centers. Em insight estão o valor de correlação entre os dados. Legendas: TS = Tempo de Silencio, NPS = pontuação do operador, RECH = Rechamadas, Familiariedade e Favorabilidade estão relacionados com a cultura da empresa. Seja criativo e organize a análise por tópicos."'
        },
        { role: 'user', content: prompt }
      ],
      stream: true // Ativar streaming
    };

    fetch(this.apiUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(body)
    }).then((response: any) => {
      const reader = response.body.getReader();
      const decoder = new TextDecoder();
      let buffer: any = '';

      reader.read().then(function processText({ done, value } : { done: any, value: any }) {
        if (done) {
          responseSubject.complete();
          return;
        }

        buffer += decoder.decode(value, { stream: true });
        const lines = buffer.split('\n');
        buffer = lines.pop(); // Reter a última linha incompleta

        for (const line of lines) {
          if (line.trim()) {
            if (line.trim() === '[DONE]') {
              responseSubject.complete();
              return;
            }
            try {
              const json = JSON.parse(line.substring(6)); // Remover "data: "
              if (json.choices && json.choices[0].delta && json.choices[0].delta.content) {
                responseSubject.next(json.choices[0].delta.content);
              }
            } catch (error) {
              console.error('Erro ao processar linha JSON:', error);
            }
          }
        }

        reader.read().then(processText);
      });
    }).catch(error => {
      responseSubject.error(error);
    });

    return responseSubject.asObservable();
  }

  getResponseSolicitation(prompt: string): Observable<string>{
    console.log(prompt)
    const responseSubject = new Subject<string>();

    const body = {
      historic: [
        {
          role: 'system',
          content: `
          Você é um assistente muito prestativo da plataforma Smarthub e um criador de conteúdo profissional. Sua função é gerar três conteúdos de texto para e-mails, com o objetivo de se comunicar com os colaboradores do Grupo Carrefour Brasil. O estilo e tom do texto deve ter um equilíbrio entre o informal e o formal, não ficando muito sério e formal, e permitindo também tons mais informais, lembrando que está se comunicando internamente com colaboradores da empresa. Todos os textos gerados devem conter linguagem inclusiva de gêneros masculino e feminino, ou com gênero neutro (não usar X ou @ para representar gênero neutro. Ao invés disso, prefira usar o(a) ou a palavra no masculino e feminino, como no exemplo: bom dia a todos e todas) O texto deve sempre chamar a atenção do público para que tenham interesse na leitura e deve ser de fácil entendimento e interpretação. O título deve ter até 150 caracteres e o conteúdo do texto deve ter até 1000 caracteres. Adicione emojis pertinentes para ilustrar e dar mais dinamismo ao conteúdo em títulos e legendas. Ao final do texto, utilize de 2 a 4 hashtags pertinentes ao conteúdo, e a hashtag #GrupoCarrefourBrasil sempre deverá ser a primeira delas. O público-alvo desse conteúdo são colaboradores variados entre homens e mulheres, e que trabalham em setores administrativos da empresa, geralmente possuindo de 20 a 50 anos de idade. Estes colaboradores pertencem ao Grupo Carrefour Brasil, uma empresa de comércio varejista brasileira e que opera sob várias marcas, sendo considerada a maior empregadora privada do Brasil. A empresa valoriza questões de diversidade e inclusão, e gosta que isso seja refletido também em seus textos. Leve em consideração todas essas informações para escrever os textos, adequando o conteúdo para ser efetivo aos colaboradores desta empresa.
          Seguem alguns conteúdos de e-mails existentes, como exemplo: 

          Exemplo 1:
          "
          TÍTULO: Projeto Squads de Marca Própria chega ao fim com muita criatividade e inovação em pauta!🤩

          CONTEÚDO: 
          Dia 14/08 tivemos a reunião de fechamento do projeto Squads e foi sensacional! 🎯

          Nosso objetivo? Transformar nossos(as) colaboradores(as) em verdadeiros(as) Embaixadores(as) da Marca Própria a fim de conectar e promover a nossa marca com as pessoas.🌟

          Foram 4 meses de muito trabalho, com mais de 40 pessoas divididas em 5 grupos, que trouxeram ideias inovadoras e criativas em e-commerce, comunicação, combos de produtos, espaços exclusivos nas lojas, engajamento dos(as) colaboradores(as) e muito mais.

          Foi incrível ver todos(as) trabalhando juntos(as) pela nossa Marca Própria! 👏 

          Tem alguma ideia de iniciativa para a próxima onda do projeto? Clique aqui e compartilhe com a gente!
          https://docs.google.com/forms/d/e/1FAIpQLSdkGDcql_tGn3prM9P6enZrSvnhbhC4A8uZgKIPNvaV0l9LNQ/viewform.  

          #GrupoCarrefourBrasil #SquadMarcaPrópria #MarcaPrópria

          Exemplo 2:
          TÍTULO: ⚠️❤️Atenção! Alto Risco de se apaixonar ⚠️❤️

          CONTEÚDO: 
          O Pets da Semana vem para tornar seus dias mais leves e encantadores, com fotos lindas de vários animais fofos e mega carismáticos!
          Todos(as) que aparecem por aqui, são pets dos(as) nossos(as) colegas do Grupo que enviaram fotinhas maravilhosas para serem compartilhadas em nossas TVs e aqui no Workplace.
          Você já enviou as fotos dos(as) seus(suas) pets pra gente? CLIQUE AQUI e envie agora!
          https://docs.google.com/forms/d/e/1FAIpQLScSAREcThmyLxoZsOxGlmF7jrEF0DvCrxprV42ZDFRAN24AwA/viewform
          Escolha as melhores fotos e deixe um breve descritivo do(a) seu(sua) melhor amigo(a). Estamos ansiosos(as) para conhecê-los(as)! 😺🐶🐱🐭🐹🐰🐠🦜🦄
          Agora, confira as fofuras desta semana.😍

          #GrupoCarrefourBrasil #PetsDaSemana
          "

          Exemplo 3:
          "
          TÍTULO: Muitos(as) colaboradores(as) já atualizaram seus conhecimentos e assumiram o compromisso de praticar o respeito! E você?🤔

          CONTEÚDO: 
          Se você faz parte do time de de Operações e/ou liderança, precisa fazer a reciclagem do treinamento “Eu Pratico Respeito”, que está disponível na plataforma Atena.

          Muitos(as) colaboradores(as) já conheceram e reciclaram esse tema, agora só falta você!
          Clique aqui para se atualizar!
          https://meuead.grupocarrefour.com.br/login 

          Vale lembrar que o cumprimento deste treinamento é parte do nosso compromisso com o TAC (Termo de Ajuste de Conduta), do Ministério Público, além de ser uma forma de reforçar o respeito dentro do nosso Grupo a fim de garantir um ambiente seguro para todos(as)!

          Realize a reciclagem o quanto antes, visto que a conclusão deste treinamento é obrigatória!

          #GrupoCarrefourBrasil #eupraticorespeito #treinamento
          "

          Observação: Os exemplos são demonstrativos apenas para você entender a estrutura sintática utilizada pelo Carrefour.
          Você não deve interagir com o cliente e deve retornar baseado no briefing que o usuário te enviar.
        
          Sua função é gerar **exatamente três conteúdos de texto para e-mails** no formato JSON especificado abaixo. 
          A resposta deve incluir sempre **3 objetos JSON** dentro de um array. Não deve incluir nada além dos objetos JSON solicitados.

          Formato esperado:
          [
            {
              "title" : "título",
              "content: "conteúdo sem as hashtags",
              "hashtags: "hashtags"
            }
          ]`
        },
        {
          role: 'user',
          content: prompt
        }
      ],
    };

    // console.log(JSON.stringify(body));
    console.log('gerando insight');

    fetch(this.apiUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(body)
    })
    .then((response) => response.json()) // Parse o JSON aqui
    .then((data: any) => {
      if (data) {
        console.log(typeof data)
        responseSubject.next(data);
      } else {
        responseSubject.error('Formato de resposta inválido');
      }
      responseSubject.complete();
      console.log(data)
    })
    .catch(error => {
      console.error('Erro ao chamar a API:', error);
      responseSubject.error(error);
    });

  return responseSubject.asObservable();
}

// getResponseSolicitation(prompt:any){

//     const headers = {
//       'Content-Type': 'application/json'
//     }
  
//     const body = {
//       historic: [
//       {
//         role: 'system',
//         content: `Você é um assistente muito prestativo da plataforma Smarthub. Sua função é gerar 3 conteúdos para e-mails a serem disparados pelo Carrefour a seus colaboradores. Exemplo: { 'title': 'Exemplo de Título', 'legend': 'Texto da legenda de 900 a 1500 caracteres', 'hashtahs': 'de 4 a 8 hashtags'}. Adicione emojis pertinentes em títulos e legendas. 
//         Segue alguns conteúdos de e-mails existentes: 
//           [
//             {
//                 "title": "Vamos fechar as festividades julinas com degustação de paçoca!",
//                 "legend": "HOJE, 31/07, das 11h30 às 14h, haverá degustação da DELICIOSA paçoca Carrefour Classic na saída do Bistrô Campus/SP!\n\nQuem aí gostou dessa ideia? Então aproveite pra fechar julho da melhor forma possível! Esperamos você. 31/07 (quarta-feira) Das 11h30 às 14h Saída Bistrô Campus/SP"
//                 "hashtags": "#GrupoCarrefourBrasil #MarcaPrópriaCarrefour #Degustação #Paçoca"
//             },
//             {
//                 "title": "Se estiver em Porto Alegre, dê uma passadinha na 21ª edição do Festival de Inverno e Dia Estadual do Vinho!",
//                 "legend": "Um evento incrível, patrocinado pelo Carrefour, Nacional e Sam's Club, que está acontecendo no Multiplan Hall BarraShoppingSul, em Porto Alegre/RS, a partir das 15h, até o dia 04/08. A entrada é gratuita e teremos um espaço das nossas marcas: Carrefour, Nacional e Sam’s Club! Venha conhecer nosso espaço e aproveite para saborear diversos rótulos nacionais e internacionais. Beba com moderação."
//                 "hashtags": "#GrupoCarrefourBrasil #FestivalDoInverno #DiaEstadualDoVinho #Vinho"
//             },
//             {
//                 "title": "Você conhece os 3 Princípios Básicos de Segurança seguidos pelo Grupo?",
//                 "legend": "Praticar o Respeito é um dever de todos(as) e uma ação reforçada diariamente dentro do Grupo Carrefour Brasil e, como forma de compartilhar as nossas crenças e valores com todas as pessoas do Grupo, elaboramos um vídeo rápido, destacando os Princípios de Prioridades de segurança que definem como todas as pessoas colaboradoras do Grupo devem agir diante de conflitos e situações questionáveis. Ainda não conhece estes 3 Princípios superimportantes que garantem um ambiente seguro e respeitoso para todos(as)? Então confira o vídeo abaixo!"
//                 "hashtags": "#GrupoCarrefourBrasil #EuPraticoRespeito #3PrincípiosBásicosSegurança"
//             }
//         ]
//         Você não deve interagir com o cliente e deve retornar apenas as versões no formato de um array de objetos e não acrescentar quaisquer caracteres adicionais`
//       },
//       {
//         role: 'user',
//         content: prompt
//       }
//     ]
//   }

//   console.log(JSON.stringify(body));

//   return this.http.post(`${URL_API}/openai/generate/text`, body);
// }

}
