import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { FormBuilder, Validators } from '@angular/forms';
import { DemandsService } from 'src/app/services/demands.service';
import { PartsService } from 'src/app/services/parts.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Clipboard } from '@angular/cdk/clipboard';
import { PartsTypesService } from 'src/app/services/parts-types.service';
import { ManagersService } from 'src/app/services/managers.service';
import { CategoryService } from 'src/app/services/category.service';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import Swal from 'sweetalert2';
import { StatusService } from 'src/app/services/status.service';
import { ClientsService } from 'src/app/services/clients.service';
import { URL_API } from 'src/app/services/api';
import { UserService } from 'src/app/services/user.service';
import { NgxFileDropEntry } from 'ngx-file-drop';

@Component({
  selector: 'app-demands-form',
  templateUrl: './demands-form.component.html',
  styleUrls: ['./demands-form.component.scss']
})
export class DemandsFormComponent implements OnInit {

  @Input() modal = false
  @Output() updateCalendar = new EventEmitter();
  @Output() cancelModal = new EventEmitter();

  dropdownSettings: IDropdownSettings = {
    idField: 'id',
    textField: 'name'
  };
  public Editor = ClassicEditor;
  loading = false

  user: any

  // users
  users: any = []

  // options list
  managersList: any = []
  categories: any;

  // status
  status: any = []
  defaultStatus: any

  // selected itens
  selectedActions:  any = []
  selectedBrands:   any = []
  selectedChannels: any = []
  selectedTargets:  any = []
  selectedManagers: any = []

  selectedCategories: any = []
  
  // part
  parts: any = []
  types: any = []
  pecaForm: any

  // demand
  demandForm: any
  demand:any;
  @Input() demand_id = 0
  recurring:any = 'recurring';
  handled:any
  recurrence_interval:any
  demand_files:any

  //files
  public files: NgxFileDropEntry[] = [];
  public readonly MAX_FILE_SIZE_MB = 20;
  greaterFiles:any = [];

  formData = new FormData();

  skeleton = false
  selectCategories: any

  approved = false

  recurrenceIntervaldropdownSettings = {
    singleSelection: true,
    idField: 'id',
    textField: 'name',
    itemsShowLimit: 10,
    enableCheckAll: false,
    allowSearchFilter: false,
    closeDropDownOnSelection: true
  }

  recurrence_interval_data:any = [
    {
      id: 1,
     name: 'Diariamente'
    },
    {
      id: 2,
     name: 'Semanalmente'
    },
    {
      id: 3,
     name: 'Mensalmente'
    },
    {
      id: 4,
     name: 'Trimestralmente'
    },
    {
      id: 5,
     name: 'Semestralmente'
    },
  ];

  constructor(
    private formBuilder: FormBuilder,
    private demandService: DemandsService,
    private partsService: PartsService,
    private partsTypesService: PartsTypesService,
    private statusService: StatusService,
    private route: ActivatedRoute,
    private router: Router,
    private clipboard: Clipboard,
    private clientService: ClientsService,
    private categoryService: CategoryService,
    private managerService: ManagersService,
    private userService: UserService
  ) { }

  async ngOnInit() {

    this.getTypes()
    this.getManagersList()
    this.getStatus()
    this.getUsers()

    let localUser = localStorage.getItem('user')?.toString()
    if (localUser) this.user = JSON.parse(localUser)

    this.demandForm = this.formBuilder.group({
      'date': ['',       Validators.required],
      'name': ['',       Validators.required],
      'content': ['',    Validators.required],
      'purpose': ['',    Validators.required],
      'end': ['',        Validators.required],
      'managers': [''],
      'handled': [''],
      'department': [''],
      'recurring': [''],
      'recurrence_interval': [''],
      'status': [''],
      'start': [''],
      'job_code': [''],
      'id': [''],
      'user_id': [''],
      'status_id': [''],
      'user': [''],
      'ai':          false,
      'categories': [''],
      'requester': [''],
      'priority': ['']
    })

    this.demandForm.controls['date'].setValue(new Date().toISOString().split('T')[0]);
    this.demandForm.controls['status'].setValue('backlog');
    this.demandForm.controls['user_id'].setValue(this.user.id);

    if(this.user.group_id == 1)
    this.demandForm.controls['user_id'].disable();

    this.route.paramMap.subscribe(params => {
      let id   = params.get('id')
      let hash = params.get('hash')
      if (id)  this.getById(id, hash)
    })

    if (this.modal) this.getById(this.demand_id)

    this.getOptions()

    this.pecaForm = this.formBuilder.group({
      'format': ['', Validators.required],
      'qtd': ['',    Validators.required],
      'month': ['',    Validators.required],
      'id': [''],
      'demanda_id': ['']
    })

    this.dropdownSettings = {
      singleSelection: false,
      idField: 'id',
      textField: 'name',
      selectAllText: 'Selecionar todos',
      unSelectAllText: 'Desmarcar todos',
      searchPlaceholderText: 'Buscar',
      itemsShowLimit: 10,
      allowSearchFilter: true
    }
  }

  getById(id: any, hash: any = null) {
   
    this.demand_id = id
    this.skeleton = true
    
    if (hash) {
      this.setClientNavigation(hash)
      return
    }

    this.demandService.getById(id).subscribe({
      next: (result: any) => {
        this.demand = result;
        this.selectedCategories = result?.selecteds;
        this.selectedManagers = result?.managers;
        this.demandForm.patchValue(result)
        this.demandForm.controls['user'].setValue(result.user?.name ? result.user?.name : this.user.name);
        this.demandForm.controls['user_id'].setValue(result.user?.id ? result.user?.id : this.user.id);
        this.recurrence_interval = this.recurrence_interval_data.filter((r:any) => {return r.id == result.recurrence_interval})
        this.skeleton = false
        this.approved = result.approved
        this.demand_files = result.files;
        console.log(result);
      },
      error: (err) => console.log(err)
    })
    this.getParts()     
  }

  getStatus() {
    this.statusService.get({ limit: 100 }).subscribe({
      next: (result: any) => this.status = result,
      error: (err) => console.log(err)
    })
  }

  getManagersList() {
    this.managerService.get({ limit: 100 }).subscribe({
      next: (result: any) => {
        this.managersList = result
      },
      error: (err) => console.log(err)
    })
  }

  setType(type: any) {
    this.pecaForm.controls['name'].setValue(type);
  }

  getInputsValue() {
    let s: any = []
    this.categories.forEach((element: any) => {
      if (element.selecteds.length > 0) {
        element.selecteds.forEach((item: any) => {
          s.push(Number(item.id))
          this.formData.append("categories[]", item.id);
        });
      }
    });
    // this.demandForm.controls['categories'].setValue(s);

    this.selectedManagers.forEach((m:any) => {
      this.formData.append('managers[]', m.id)
    });

    if(this.demandForm.controls['recurrence_interval']?.value?.length > 0)
     this.formData.append('recurrence_interval', this.demandForm.controls['recurrence_interval'].value[0].id)
  }

  getUsers() {
    this.userService.get().subscribe({
      next: (result:any) => {
        this.users = result.data
        console.log(result.data)
      },
      error: (err) => console.log(err)
    })
  }

  save(data: any) {
    this.loading = true
    
    Object.keys(data.controls).forEach(key => {
      const value = data.get(key)?.value;
      
      // Verifica se o valor não é nulo ou indefinido
      if (value !== null && value !== undefined) {
        this.formData.append(key, value);
        console.log(this.formData.getAll(key));
      }
    })

    console.log(this.demandForm)
    
    this.getInputsValue();
    
    this.demandService.save(this.formData).subscribe({
      next: (result: any) => {
        console.log(result);
        if (this.parts.length > 0) {
          this.demand_id = result.id
          this.parts.forEach((part: any) => {
            if (!part.id)
              this.addPeca({ value: part })
          });
        }

        this.loading = false

        Swal.fire({
          position: 'top-end',
          icon: 'success',
          text: this.approved ? 'Demanda salva com sucesso' : 'Demanda aprovada com sucesso',
          showConfirmButton: false,
          timer: 1500
        })

        if(!this.approved) this.approved = true
        this.modal ? 
            this.updateCalendar.emit(true) :
            this.router.navigateByUrl(`/demands/edit/${result.id}`) 
        
      },
      error: (err) => {
        console.log(err)
        this.loading = false
      }
    })
  }

  cancel() {
    this.modal ? 
            this.cancelModal.emit(true) :
            this.router.navigateByUrl(`/demands`) 
  }

  addPeca(data: any) {
    if (this.demand_id) {
      this.partsService.save(data.value, this.demand_id).subscribe({
        next: () => {
          this.getParts()
          this.pecaForm.reset()
        },
        error: (err) => console.log(err)
      })
    } else {
      this.parts.push(data.value)
    }
  }

  getParts() {
    this.partsService.get(this.demand_id).subscribe({
      next: (result) => this.parts = result,
      error: (err) => console.log(err)
    })
  }

  getTypes() {
    this.partsTypesService.get({limit: 100}).subscribe({
      next: (result: any) => this.types = result,
      error: (err) => console.log(err)
    })
  }

  deletePart(part: any) {
    Swal.fire({
      icon: 'warning',
      text: `Deseja remover a peça ${part.format}?`,
      showCancelButton: true,
      confirmButtonText: 'Excluir',
      cancelButtonText: 'Cancelar',
      confirmButtonColor: '#f5365c',
    }).then(result => {
      if (result.value) {
        if (part.id) {
          this.partsService.delete(part.id).subscribe({
            next: () => this.getParts(),
            error: (err) => console.log(err)
          })
        } else {
          this.parts = this.parts.filter((item: any) => item.format != part.format)
        }
      }
    })
  }

  getOptions() {
    this.categoryService.get().subscribe({
      next: (result:any) => {
        this.categories = result.data;
        if(this.categories){
        this.categories.forEach((c: any) => {
          let s: any = []
          c.values.forEach((value: any) => {
            if (this.selectedCategories.includes(value.id)) s.push(value);
          });
          c.selecteds = s;
        });
      }
      },
      error: (err) => console.log(err)
    })
  }

  viewText() {
    this.demandService.getText(this.demand_id).subscribe({
      next: (result: any) => {
        Swal.fire({
          title: 'Versão texto',
          customClass: "text-version",
          width: 800,
          html: result.response,
          showConfirmButton: false,
          showCloseButton: true,
          confirmButtonText: 'ok'
        })
      }
    })
  }

  copyText() {
    this.demandService.getText(this.demand_id).subscribe({
      next: (result: any) => {
        this.clipboard.copy(result.response)
        Swal.fire({
          position: 'top-end',
          icon: 'success',
          text: 'Texto copiado com sucesso',
          showConfirmButton: false,
          timer: 1500
        })
      }
    })
  }

  copyUrlTracking() {
    this.clipboard.copy(`${URL_API}/email/access/tracking/${this.demand_id}`)
    Swal.fire({
      position: 'top-end',
      icon: 'success',
      text: 'Url de rastreamento copiada com sucesso',
      showConfirmButton: false,
      timer: 1500
    })
  }

  setClientNavigation(hash: any) {
    this.clientService.getByHash(hash).subscribe({
      next: (result: any) => { 
        console.log(result)
        this.clientService.setClientNavigation(result.id.toString()).subscribe({
          next: () => { 
            localStorage.setItem('client_id', result.id)  
            this.router.navigateByUrl(`demands/edit/${this.demand_id}`)
          },
          error: (err) => console.log(err)
        })
      },
      error: (err) => console.log(err)
    })
  }

  public dropped(files: NgxFileDropEntry[]) {
    // this.files = files;
    for (const droppedFile of files) {

      if (droppedFile.fileEntry.isFile) {
        const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
        fileEntry.file((file: File) => {

           let fileSizeMB = file.size / (1024 * 1024);
           if (fileSizeMB > this.MAX_FILE_SIZE_MB) {
             this.greaterFiles.push(`${droppedFile.relativePath} (${fileSizeMB.toFixed(2)} MB)`);
           } else {
            this.greaterFiles = []
            this.files.push(droppedFile);
            console.log(droppedFile.relativePath);
            this.formData.append('files[]', file, droppedFile.relativePath);
        }
        });
      } else {
        const fileEntry = droppedFile.fileEntry as FileSystemDirectoryEntry;
        console.log(droppedFile.relativePath, fileEntry);
      }
    }
  }

  public fileOver(event:any){
    console.log(event);
  }

  public fileLeave(event:any){
    console.log(event);
  }

}
