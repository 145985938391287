import { LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { registerLocaleData } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './pages/login/login.component';
import { HomeComponent } from './pages/home/home.component';
import { SideMenuComponent } from './components/side-menu/side-menu.component';
import { NavComponent } from './components/nav/nav.component';
import { DemandsComponent } from './pages/demands/demands.component';
import { AnalyticsComponent } from './pages/analytics/analytics.component';
import { NgApexchartsModule } from 'ng-apexcharts';
import { DemandsFormComponent } from './pages/demands/demands-form/demands-form.component';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { BoxStatusComponent } from './components/box-status/box-status.component';
import { BoxPieChartComponent } from './components/box-pie-chart/box-pie-chart.component';
import { BoxBarChartComponent } from './components/box-bar-chart/box-bar-chart.component';
import { DemandTableComponent } from './components/demand-table/demand-table.component';
import { JwtModule } from '@auth0/angular-jwt';
import { KabanComponent } from './pages/kaban/kaban.component';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { BoxKanbanComponent } from './components/box-kanban/box-kanban.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { CategoriesComponent } from './pages/categories/categories.component';
import { CategoriesFormComponent } from './pages/categories/categories-form/categories-form.component';
import { PartTypesComponent } from './pages/part-types/part-types.component';
import { PartTypesFormComponent } from './pages/part-types/part-types-form/part-types-form.component';
import { ManagersComponent } from './pages/managers/managers.component';
import { DefaultTableComponent } from './components/default-table/default-table.component';
import { ManagersFormComponent } from './pages/managers/managers-form/managers-form.component';
import { UsersComponent } from './pages/users/users.component';
import { UsersFormComponent } from './pages/users/users-form/users-form.component';
import { InterceptorModule } from './interceptors/interceptor.module';
import { SafePipe } from './pipe/safe.pipe';
import { SlugifyPipe } from './pipe/slugify';
import { PageDefaultComponent } from './shared/page-default/page-default.component';
import { SolicitationFormComponent } from './pages/solicitation/solicitation-form/solicitation-form.component';
import { SolicitationComponent } from './pages/solicitation/solicitation.component';
import { StatusComponent } from './pages/status/status.component';
import { StatusFormComponent } from './pages/status/status-form/status-form.component';
import { IconPickerModule } from 'ngx-icon-picker';
import { ClientsComponent } from './pages/clients/clients.component';
import { ClientsFormComponent } from './pages/clients/clients-form/clients-form.component';
import { EmailEditorModule } from 'angular-email-editor';
import { SmartmailComponent } from './pages/smartmail/smartmail.component';
import { SmartmailEditorComponent } from './pages/smartmail/smartmail-editor/smartmail-editor.component';
import { FileSaverModule } from 'ngx-filesaver';
import { SmartmailAnalyticsComponent } from './pages/smartmail/smartmail-analytics/smartmail-analytics.component';
import { DashtreeComponent } from './pages/dashtree/dashtree.component';
import { SmartmailAnalyticsDetailComponent } from './pages/smartmail/smartmail-analytics/smartmail-analytics-detail/smartmail-analytics-detail.component';
import { LottieModule } from 'ngx-lottie';
import player from 'lottie-web';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { CalendarComponent } from './pages/calendar/calendar.component';
import { NgxQRCodeModule } from '@techiediaries/ngx-qrcode';
import localept from '@angular/common/locales/pt';
import { QrcodeComponent } from './pages/qrcode/qrcode.component';
import { QrcodeFormComponent } from './pages/qrcode/qrcode-form/qrcode-form.component';
import { QrcodeAnalyticsComponent } from './pages/qrcode/qrcode-analytics/qrcode-analytics.component';
import { QrcodeAnalyticsDetailComponent } from './pages/qrcode/qrcode-analytics/qrcode-analytics-detail/qrcode-analytics-detail.component';
import { NgbModalModule, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ErrorComponent } from './pages/error/error.component';
import { HtmlViewComponent } from './components/html-view/html-view.component';
import { SmartdataComponent } from './pages/smartdata/smartdata.component';
import { SmartdataDetailComponent } from './pages/smartdata/smartdata-detail/smartdata-detail.component';
import { NgxFileDropModule } from 'ngx-file-drop';
import { NgxFileUploadUiProgressbarModule, NgxFileUploadUiCommonModule, NgxFileUploadUiToolbarModule } from "@ngx-file-upload/ui";
import { TesteComponent } from './pages/teste/teste.component';
import { AnalyticCategoriesComponent } from './pages/analytic-categories/analytic-categories.component';
import { AnalyticCategoriesFormComponent } from './pages/analytic-categories/analytic-categories-form/analytic-categories-form.component';

registerLocaleData(localept, 'pt');

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    HomeComponent,
    SideMenuComponent,
    NavComponent,
    DemandsComponent,
    AnalyticsComponent,
    DemandsFormComponent,
    BoxStatusComponent,
    BoxPieChartComponent,
    BoxBarChartComponent,
    DemandTableComponent,
    KabanComponent,
    BoxKanbanComponent,
    CategoriesComponent,
    CategoriesFormComponent,
    PartTypesComponent,
    PartTypesFormComponent,
    ManagersComponent,
    DefaultTableComponent,
    ManagersFormComponent,
    UsersComponent,
    UsersFormComponent,
    SolicitationComponent,
    SolicitationFormComponent,
    SafePipe,
    SlugifyPipe,
    PageDefaultComponent,
    StatusComponent,
    StatusFormComponent,
    ClientsComponent,
    ClientsFormComponent,
    SmartmailComponent,
    SmartmailEditorComponent,
    SmartmailAnalyticsComponent,
    DashtreeComponent,
    SmartmailAnalyticsDetailComponent,
    CalendarComponent,
    QrcodeComponent,
    QrcodeFormComponent,
    QrcodeAnalyticsComponent,
    QrcodeAnalyticsDetailComponent,
    ErrorComponent,
    HtmlViewComponent,
    SmartdataComponent,
    SmartdataDetailComponent,
    TesteComponent,
    AnalyticCategoriesComponent,
    AnalyticCategoriesFormComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    NgApexchartsModule,
    ReactiveFormsModule,
    DragDropModule,
    CommonModule,
    NgSelectModule,
    BrowserAnimationsModule,
    FormsModule,
    NgMultiSelectDropDownModule.forRoot(),
    HttpClientModule,
    NgxSkeletonLoaderModule,
    CKEditorModule,
    IconPickerModule,
    EmailEditorModule,
    FileSaverModule,
    NgxQRCodeModule,
    NgbModalModule,
    LottieModule.forRoot({ player: playerFactory }),
    CalendarModule.forRoot({
      provide: DateAdapter,
      useFactory: adapterFactory,
    }),
    JwtModule.forRoot({
      config: {
        tokenGetter: jwtTokenGetter,
        allowedDomains: ["localhost:4200", "smarthub.alamo.com.vc", "smarthubdev.alamo.com.vc", "127.0.0.1:8000"],
        authScheme: "Bearer " // Default value
      }
    }),
    HttpClientModule,
    InterceptorModule,
    NgbModule,
    NgxFileDropModule,
    NgxFileUploadUiProgressbarModule,
    NgxFileUploadUiCommonModule, 
    NgxFileUploadUiToolbarModule

  ],
  providers: [SlugifyPipe, { provide: LOCALE_ID, useValue: 'pt' }],
  bootstrap: [AppComponent]
})
export class AppModule { }

export function jwtTokenGetter() {
  return localStorage.getItem('access_token');
}

export function playerFactory() {
  return player;
}